
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
interface HeatInfo {
  heat: Array<{ lng: number; lat: number; count: number }>;
  project: {
    lng: number;
    lat: number;
  };
}
@Component
export default class HeatMap extends Vue {
  private map: AMap.Map | undefined = undefined
  private searchInfo: { projectName: string; dateRange: Array<string> } = {
    projectName: '',
    dateRange: []
  }

  created (): void {
    this.initTime()
    this.getData()
  }

  destroyed (): void {
    if (this.map) {
      this.map.clearMap()
    }
  }

  initTime () {
    this.searchInfo.dateRange = [this.$dayjs().subtract(29, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
  }

  // 获取热力图数据
  getData (): void {
    const date = this.searchInfo.dateRange && this.searchInfo.dateRange[0] ? {
      startDate: this.searchInfo.dateRange[0],
      endDate: this.searchInfo.dateRange[1]
    } : {}
    this.$axios.get<HeatInfo>(this.$apis.inspection.selectIssueHeat, {
      ...date,
      projectName: this.searchInfo.projectName
    }).then(res => {
      this.loadMap(res)
    })
  }

  // 渲染地图
  loadMap (info: HeatInfo) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: ['AMap.HeatMap']
    }).then(() => {
      let center: [number, number] = [118.949758, 32.051562]
      let zoom = 10
      if (info.project && info.project.lng) {
        center = [Number(info.project.lng), Number(info.project.lat)]
        zoom = 14
      }
      const map = new AMap.Map('map', {
        center: center,
        zoom: zoom
      })
      this.map = map
      const heatmap: AMap.Heatmap = new (AMap as any).HeatMap(map, { radius: 50 })
      // 设置数据集
      heatmap.setDataSet({ data: info.heat })
    })
  }
}
